import React from 'react';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles({
  banner: {
    fontSize: '30px',
    width: '90%',
    zIndex: 1040,
    alignItems: 'flex-end',
    top: 'initial !important',
  },
  alert: {
    width: '100%',
    fontSize: '18px',
    backgroundColor: '#0077d1',
    '& .MuiAlert-message': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    }
  },
  button: {
    backgroundColor: '#0077d1',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      color: '#0077d1',

      '& .icon-exit:before': {
        color: '#0077d1',
      },
    },
    '& .icon-exit:before': {
      fontSize: '14px',
      lineHeight: '14px',
      marginRight: '10px',
      color: 'white',
      transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }
  }
});

export default function LTISelectModeBanner({ show, courseName, lmsName }): JSX.Element {
  const styles = useStyles({});
  const isOpen = true;

  return show && <Snackbar
    className={styles.banner}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={isOpen}
    onClose={() => {}}
    message=""
    key={'ltiSelectAssignmentBanner'}>
    <Alert elevation={6} variant="filled" className={styles.alert} severity="info">
      <span>Assigning content to your "{courseName}" {lmsName} course.</span>
    </Alert>
  </Snackbar>;
}
