import React from 'react';
import Problem from './problem/container';
import ProblemHeader from './problem/header/container';
import Step from './step/container';
import StepHeader from './step/header/container';
import './common/learnosityQuestion/learnosityOverrides.css';
import { handleExitButtonClick, parseQueryString } from '../../utils/common';
import { sendMessage } from '../../utils/iframe';
import showAlert from '../common/toaster';
import { utcToLocal } from '../../utils/common';
import { checkRightProblemOrderLoadingAndRedirect } from '../../utils/common';
import LevelSetLocationModal from '../reader/modals/levelSetLocation/container';
import { Modal } from 'sharedfrontend';
import { closeModal, openModal } from '../../actions/eventEmitter';
import { addReadSpeaker } from '../../utils/readspeaker';

class Reader extends React.Component<any, any> {
  async componentDidMount() {
    const { problemSetId, classId, problemId, assignmentId } = parseQueryString(this.props.location.search);

    // Redirect old reader url with assignmentId to new
    if (assignmentId) {
      const { problemSetId, classId } = await this.props.getReaderNewUrlParams(assignmentId);
      window.location.href = `mathreader?problemSetId=${problemSetId}&classId=${classId}`;
    }
    else {
      this.props.fetchData({
        problemSetId,
        classId,
        problemIdFromUrl: problemId,
        callback: fetchDataCallback,
      });

      addReadSpeaker();
    }
  }

  componentDidUpdate(prevProps: any) {
    const loadedWithoutProblemIdInUrl = this.props.location.search.indexOf('problemId') === -1;

    if (prevProps.currentProblemId !== this.props.currentProblemId) {
      if (this.props.isLocked) {
        const { startDate, endDate } = this.props;
        const message =
          startDate && endDate
            ? `This assignment is accessible between ${utcToLocal(
              startDate
            )} - ${utcToLocal(endDate)}`
            : `This assignment is accessible after ${utcToLocal(startDate)}`;

        showAlert({ message: `${message}! Exiting now.`, isError: true });
        window.setTimeout(
          () => handleExitButtonClick({ classId: this.props.classId }),
          3000
        );
        return;
      }

      const newUrl = loadedWithoutProblemIdInUrl
        ? `/mathreader${this.props.location.search}&problemId=${this.props.currentProblemId}`
        : `/mathreader${this.props.location.search.replace(
          'problemId=' + prevProps.currentProblemId,
          'problemId=' + this.props.currentProblemId
        )}`;

      if (loadedWithoutProblemIdInUrl) {
        this.props.history(newUrl, { replace: true });
      }
      else {
        this.props.history(newUrl);
      }

      sendMessage('UPDATE_HASH', { path: newUrl });

      this.checkAndShowLevelSetLocationPopup();
    }
    else {
      const { problemId } = parseQueryString(this.props.location.search);

      if (
        problemId &&
        this.props.currentProblemId &&
        problemId !== this.props.currentProblemId
      ) {
        const newUrl = `/mathreader${this.props.location.search.replace(
          'problemId=' + problemId,
          'problemId=' + this.props.currentProblemId
        )}`;

        this.props.history.push(newUrl);
        sendMessage('UPDATE_HASH', { path: newUrl });
      }
    }
  }

  checkAndShowLevelSetLocationPopup = () => {
    const { isBenchmark, isStudent, levelSetLocation, saveLevelSetLocation } =
      this.props;

    if (isBenchmark && isStudent && !levelSetLocation) {
      openModal(
        <LevelSetLocationModal saveLevelSetLocation={saveLevelSetLocation} />
      );
    }
  };

  componentWillUnmount() {
    this.props.clearReaderState();
  }

  render() {
    const { currentProblemId, currentStepId, isLocked, showStepAnswer } =
      this.props;
    const { problemSetId, classId, assignmentId, reveal } = parseQueryString(
      this.props.location.search
    );
    const isRightProblemOrderLoading = checkRightProblemOrderLoadingAndRedirect(
      { ...this.props, problemSetId, classId } as any
    );

    if (
      assignmentId ||
      !currentProblemId ||
      isLocked ||
      !isRightProblemOrderLoading
    ) {
      return null;
    }
    else {
      return currentStepId ? (
        <>
          <StepHeader />
          <Step showStepAnswer={showStepAnswer} />
        </>
      ) : (
        <>
          <ProblemHeader revealBookTitle={reveal} />
          <Problem />
        </>
      );
    }
  }
}

export default Reader;

const fetchDataCallback = (params: any) => {
  const {
    completedProblemIds,
    problemIds,
    classId,
    isBenchmark,
    problemSetName,
    isInvalidLevelSetAttempt,
  } = params;

  if (!isBenchmark) {
    if (isInvalidLevelSetAttempt) {
      openModal(
        <Modal
          title="Oops!"
          maxWidth="sm"
          okText="Exit"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText=""
        >
          <p>
            A problem has occurred, notify your teacher to check for a message
            in their LevelSet report.
          </p>
        </Modal>
      );
    }
    else if (
      completedProblemIds &&
      completedProblemIds.length === problemIds.length
    ) {
      openModal(
        <Modal
          title="Awesome!"
          maxWidth="sm"
          okText="Exit"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText=""
        >
          <p>You have completed {problemSetName}!</p>
        </Modal>
      );
    }
  }
};
