const sharedConfig = {
  appName: 'Achieve3000 Math',
  reportExportDisableTimout: 2 * 60 * 1000,
  achieve3000HostedVideoLink: 'https://prod-cms-content.achieve3000.com/Content/Public/Downloaded',
};

const local = {
  backendApiUrl: 'http://localhost:5000',
  alFrontendUrl: 'http://localhost:5002',
  alBackendUrl: 'http://localhost:5003',
  cookieDomain: 'localhost'
};

const staging = {
  backendApiUrl: 'https://math-api-staging.achieve3000.com',
  alFrontendUrl: 'https://math-staging.achieve3000.com',
  alBackendUrl: 'https://al-api-staging.achieve3000.com',
  cookieDomain: '.achieve3000.com'
};

const nonprod = {
  backendApiUrl: 'https://math-api-nonprod.achieve3000.com',
  alFrontendUrl: 'https://math-nonprod.achieve3000.com',
  alBackendUrl: 'https://al-api-nonprod.achieve3000.com',
  cookieDomain: '.achieve3000.com'
};

const live = {
  backendApiUrl: 'https://math-api-live.achieve3000.com',
  alFrontendUrl: 'https://math-live.achieve3000.com',
  alBackendUrl: 'https://al-api-live.achieve3000.com',
  cookieDomain: '.achieve3000.com'
};

const prod = {
  backendApiUrl: 'https://math-api-prod.achieve3000.com',
  alFrontendUrl: 'https://math.achieve3000.com',
  alBackendUrl: 'https://al-api.achieve3000.com',
  cookieDomain: '.achieve3000.com'
};

const config = process.env.REACT_APP_ENV === 'production' ? prod
  : process.env.REACT_APP_ENV === 'live' ? live
    : process.env.REACT_APP_ENV === 'staging' ? staging
      : process.env.REACT_APP_ENV === 'nonprod' ? nonprod
        : local;

export default {
  ...sharedConfig,
  ...config,
};