import React from 'react';
import ExternalLink from '../ExternalLink';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  expiryAlertContainer: {
    background: '#0077D1',
    color: '#FFF',
    padding: '16px',
    fontSize: '16px',
    fontFamily: 'WorkSans-SemiBold',
    display: 'flex',
    position: 'relative',
  },
  alertText: {
    flex: '1',
    textAlign: 'center',
    alignSelf: 'center',
  },
  learnMoreButton: {
    color: '#FFF !important',
    fontSize: '16px',
    fontFamily: 'WorkSans-SemiBold',
    textDecoration: 'underline !important',
    transition: 'background-color 0.3s ease-in-out',
  },
  closeButton: {
    color: '#FFF',
    float: 'right',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: 1,
    textShadow: '0 1px 0 #fff',
    filter: 'alpha(opacity=20)',
    opacity: 1,
    '-webkit-appearance': 'none',
    padding: 0,
    cursor: 'pointer',
    background: 'transparent',
    border: 0,

    '&::hover, &::focus': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      opacity: 0.5,
    }
  }
});

interface IProps {
  closeAlert: any;
  expireDate: string;
};

const PromotionAlert = ({ closeAlert, expireDate }: IProps) => {
  const styles = useStyles();

  function getFormattedDate(dateVal): string {
    const date = new Date(dateVal).toDateString();
    const dateStr = date.substr(date.indexOf(' ') + 1);
    const index = dateStr.lastIndexOf(' ');

    return dateStr.substring(0, index) + ', ' + dateStr.substring(index + 1);
  }

  return (
    <div className={styles.expiryAlertContainer}>
      <span className={styles.alertText}>
        Your Actively Learn Prime Trial expires on {expireDate ? getFormattedDate(expireDate) : ''}.&nbsp;
        <ExternalLink
          className={styles.learnMoreButton}
          href='https://www.mheducation.com/prek-12/contact/contact-rep/contact-rep-supplemental.html?cid=web|mhse&utm_SEG_Product_Interest=Actively Learn.html'>
          Contact us
        </ExternalLink> to upgrade.
      </span>
      <button
        className={styles.closeButton}
        type="button"
        data-dismiss="alert"
        onClick={closeAlert}
      >
        <span aria-hidden="true">&times;</span>
        <span className="visuallyHidden"></span>
      </button>
    </div>
  );
};

export default PromotionAlert;