import Reader from './component';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getData, postData, getDataPlain } from '../../actions/api';
import { exitReader } from '../../actions/readerNavigation';
import { Role } from '../../utils/constants';
import { closeModal } from '../../actions/eventEmitter';

const mapStateToProps = (state: any) => {
  return {
    currentProblemId: state.readerNavigation.currentProblemId,
    currentStepId: state.readerNavigation.currentStepId,
    isLocked: state.userData.role === Role.STUDENT && state.problemSetData.isLocked,
    startDate: state.problemSetData.startDate,
    endDate: state.problemSetData.endDate,
    classId: state.problemSetData.classId,
    isBenchmark: state.problemSetData.isBenchmark,
    levelSetLocation: state.problemSetData.levelSetLocation,
    isStudent: state.userData.role === Role.STUDENT,
    completedProblemIds: state.problemSetData.completedProblemIds,
    problemIds: state.problemSetData.problemIds,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: async ({
      problemSetId, classId, problemIdFromUrl, callback,
    }: {
      problemSetId: number;
      classId?: number;
      problemIdFromUrl?: number;
      callback: (body: any) => void;
    }) => {
      dispatch(getData({
        type: 'PROBLEMSET_DATA',
        params: { problemSetId, classId },
        mutators: {
          onResolve: (resolveAction: any) => {
            const { problemId, stepId } = getProblemOrStepToShow(resolveAction.body, problemIdFromUrl);

            const body = {
              ...resolveAction.body,
              problemId,
              stepId,
              problemSetId,
              classId,
            };

            callback(body);

            return {
              ...resolveAction,
              body,
            }
          },
        },
      }));
    },
    clearReaderState: () => {
      dispatch(exitReader());
    },
    saveLevelSetLocation: (params: any) => {
      dispatch(postData({
        type: 'TRACK_LEVELSET_LOCATION',
        params
      }));
    },
    getReaderNewUrlParams: async (assignmentId: number) => {
      return getDataPlain({
        type: 'GET_READER_NEW_URL_PARAMS',
        params: { assignmentId }
      })
    },
    showStepAnswer: (stepId: number, problemSetId: number, classId: number) => {
      dispatch(postData({
        type: 'SHOW_STEPANSWER',
        params: { stepId, problemSetId, classId },
        mutators: {
          onResolve: (resolveAction: any) => {
            closeModal();

            return {
              ...resolveAction,
              body: {
                ...resolveAction.body,
                stepId,
              },
            };
          },
        }
      }));
    },
  };
};

const withLocation = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return <Component {...props} location={location} history={navigate} params={params} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocation(Reader));

const getProblemOrStepToShow = (response: any, problemIdFromUrl?: number) => {
  const { stepId } = response.position || {};

  if (stepId) {
    return response.position;
  }
  else if (problemIdFromUrl) {
    return { problemId: problemIdFromUrl };
  }
  else {
    return response.position || { problemId: response.problemIds[0] };
  }
};
