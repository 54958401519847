import React from 'react';
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as autoLogoutUtils from './utils/autoLogout';
import * as iframeUtils from './utils/iframe';
import { SessionInfo } from './utils/constants';
import ValidateRoute from './components/validateRoute';
import Admin from './components/admin/adminReports.container';
import Catalog from './components/catalog/container';
import Reveal from './components/reveal/container';
import ClassData from './components/classData/container';
import StudentData from './components/studentData/studentData.container';
import StudentView from './components/studentView/container';
import Reader from './components/reader/container';
import RedirectToAL from './components/redirectToAL';
import { eventEmitter, Events } from './actions/eventEmitter';
import muiTheme from './utils/muiTheme';
import './styles/layout.css';
import './styles/spacing.css';
import './styles/popup.css';
import './styles/text.css';

autoLogoutUtils.checkForAutoLogoutOnLoad();
iframeUtils.startListener(store);

const App = ():any => {
  const [modalElements, setModalElements] = React.useState([] as any);

  useIdleTimer({
    element: document,
    debounce: 250,
    timeout: SessionInfo.maxInactiveTime,
    onIdle: autoLogoutUtils.onIdle,
    onAction: autoLogoutUtils.onAction,
  });

  eventEmitter.subscribe(Events.OPEN_MODAL, (element: JSX.Element) => setModalElements([...modalElements, element]));
  eventEmitter.subscribe(Events.CLOSE_MODAL, (count = 1) => setModalElements([...modalElements].slice(0, -count)));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/mathreader" element={<ValidateRoute component={Reader} />} />
              <Route path="/catalog" element={<ValidateRoute component={Catalog} />} />
              <Route path="/reveal/:bookTitleSlug?" element={<ValidateRoute component={Reveal} />} />
              <Route path="/school" element={<ValidateRoute component={Admin} />} />
              <Route path="/classdata/:classId/:tabName" element={<ValidateRoute component={ClassData} />} />
              <Route path="/studentdata/:classId/:tabName" element={<ValidateRoute component={StudentData} />} />
              <Route path="/studentview/:classId" element={<ValidateRoute component={StudentView} />} />
              <Route path="*" element={<RedirectToAL />} />
            </Routes>
          </BrowserRouter>
          {modalElements.map((elem: any, idx: number) => <React.Fragment key={idx}>{elem}</React.Fragment>)}
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;